<template>
  <div class="app-container">
    <el-form :inline="true" :model="shoppingForm" class="demo-form-inline">
      <span @click="getAll">
        <el-form-item label="获取所有" prop="all" style="margin:0 0 0 50px">
          <el-switch v-model="shoppingForm.all"></el-switch>
        </el-form-item>
      </span>
      <el-form-item label="服务平台" style="margin:0 20px 0 50px">
        <el-select v-model="shoppingForm.platform" placeholder="服务平台">
          <el-option label="Twitter" value="Twitter"></el-option>
          <el-option label="Facebook" value="Facebook"></el-option>
          <el-option label="YouTube" value="YouTube"></el-option>
          <el-option label="Instagram" value="Instagram"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务类别">
        <el-select v-model="shoppingForm.category" placeholder="服务类别">
          <el-option label="点赞" value="点赞"></el-option>
          <el-option label="评论" value="评论"></el-option>
          <el-option label="转发" value="转发"></el-option>
          <el-option label="浏览" value="浏览"></el-option>
          <el-option label="订阅" value="订阅"></el-option>
          <el-option label="粉丝" value="粉丝"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm">重置</el-button>
        <el-button @click="addShopping('添加服务')" type="success">
          添加服务
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      style="width: 100%"
      v-loading="listLoading"
      border
      fit
      highlight-current-row
      class="tableList3"
    >
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column
        prop="platform"
        label="服务平台"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="category"
        label="服务类别"
        width="150"
      ></el-table-column>
      <el-table-column prop="name" label="服务标题"></el-table-column>
      <el-table-column
        prop="smallest"
        label="最少数量"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="maximum"
        label="最大数量"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="服务价格"
        width="100"
      ></el-table-column>
      <el-table-column prop="state" label="服务可用状态" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state === 1" type="success" effect="dark">
            正常
          </el-tag>
          <el-tag v-else>下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="desciption"
        label="服务描述"
        width="350px"
      ></el-table-column>
      <el-table-column prop="create_time" label="上架时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="changeShopping(scope.row, '更改服务')"
            type="text"
            size="small"
          >
            更改服务
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="shoppingForm.all === false"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageIndex"
      :page-sizes="[pageSize, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>

    <el-dialog
      :title="showShoppingTitle"
      :visible.sync="showShoppingAssign"
      @close="dialogClose"
    >
      <editShopping
        :shopping.sync="shopping"
        @closedialog="close"
        v-if="showShoppingTitle === '更改服务'"
      ></editShopping>
      <addShopping
        v-if="showShoppingTitle === '添加服务'"
        @closedialog="close"
      ></addShopping>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import { getshoppinglist } from '@/api/shopping'
import editShopping from '@/components/shopping/editShopping'
import addShopping from '@/components/shopping/addShopping.vue'

export default {
  components: { editShopping, addShopping },
  data() {
    return {
      shoppingForm: { all: false, platform: '', category: '' },
      tableData: [],
      pageIndex: 1,
      pageSize: 5,
      total: 0,
      showShoppingAssign: false,
      showShoppingTitle: null,
      shopping: null,
      addshopping: [],
      listLoading: true
    }
  },
  created() {
    this.getShoppingList()
  },
  methods: {
    // 获取服务列表
    getShoppingList() {
      this.listLoading = true
      getshoppinglist({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        all: this.shoppingForm.all,
        platform: this.shoppingForm.platform,
        category: this.shoppingForm.category
      }).then(resp => {
        if (this.shoppingForm.all === false) {
          this.tableData = resp.items
          this.total = resp.all_count
        } else {
          this.tableData = resp
        }
        this.listLoading = false
      })
    },
    // 查询按钮
    onSubmit() {
      console.log(this.shoppingForm.platform, this.shoppingForm.category)
      this.getShoppingList()
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.pageSize = val
      this.getShoppingList()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.pageIndex = val
      this.getShoppingList()
    },
    // 获取所有
    getAll() {
      this.getShoppingList()
    },
    // 重置
    resetForm() {
      this.shoppingForm.platform = ''
      this.shoppingForm.category = ''
      this.getShoppingList()
    },
    // 更改服务
    changeShopping(row, type) {
      this.shopping = row
      this.showShoppingAssign = true
      this.showShoppingTitle = type
    },
    // 添加服务
    addShopping(type) {
      this.showShoppingAssign = true
      this.showShoppingTitle = type
    },
    // 关闭弹窗
    close(data) {
      this.showShoppingAssign = data
    },
    dialogClose() {
      this.getShoppingList()
    }
  }
}
</script>

<style></style>

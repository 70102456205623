<template>
  <div>
    <el-form
      ref="shoppingForm"
      :model="shoppingForm"
      :rules="shoppingFormRules"
      label-width="80px"
    >
      <el-form-item label="服务标题" prop="name">
        <el-input v-model="shoppingForm.name"></el-input>
      </el-form-item>
      <el-form-item label="服务平台" prop="platform">
        <el-select v-model="shoppingForm.platform" placeholder="请选择服务平台">
          <el-option label="Twitter" value="Twitter"></el-option>
          <el-option label="Facebook" value="Facebook"></el-option>
          <el-option label="YouTube" value="YouTube"></el-option>
          <el-option label="Instagram" value="Instagram"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务类别" prop="category">
        <el-select v-model="shoppingForm.category" placeholder="请选择服务类别">
          <el-option label="点赞" value="点赞"></el-option>
          <el-option label="评论" value="评论"></el-option>
          <el-option label="转发" value="转发"></el-option>
          <el-option label="浏览" value="浏览"></el-option>
          <el-option label="订阅" value="订阅"></el-option>
          <el-option label="粉丝" value="粉丝"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最大数量" prop="maximum">
        <el-input
          v-model.number="shoppingForm.maximum"
          class="shortInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="最少数量" prop="smallest">
        <el-input
          v-model.number="shoppingForm.smallest"
          class="shortInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务价格" prop="price">
        <el-input
          v-model.number="shoppingForm.price"
          class="shortInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务状态" prop="state">
        <el-radio-group v-model="shoppingForm.state">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="0">下架</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="服务描述" prop="desciption">
        <el-input type="textarea" v-model="shoppingForm.desciption"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addShopping">
          添加服务
        </el-button>
        <el-button @click="closeDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addshopping } from '@/api/shopping'

export default {
  data() {
    const low = (rule, value, callback) => {
      if (Number(value) > Number(this.shoppingForm.maximum)) {
        callback(new Error('不能大于最大数量'))
      } else {
        callback()
      }
    }

    // }
    const high = (rule, value, callback) => {
      if (Number(value) < Number(this.shoppingForm.smallest)) {
        callback(new Error('不能小于最少数量'))
        console.log(this.shoppingForm.low + '------' + this.shoppingForm.high)
      } else {
        callback()
      }
    }

    return {
      shoppingForm: {
        name: '',
        category: '',
        create_time: '',
        maximum: '',
        smallest: '',
        price: '',
        state: '',
        desciption: ''
      },
      shoppingFormRules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            message: '服务标题不能为空'
          }
        ],
        platform: [
          {
            required: true,
            trigger: 'blur',
            message: '服务平台不能为空'
          }
        ],
        category: [
          {
            required: true,
            trigger: 'blur',
            message: '服务类别不能为空'
          }
        ],
        maximum: [
          {
            required: true,
            trigger: 'blur',
            message: '最大数量不能为空'
          },
          {
            type: 'number',
            min: 0,
            message: '最大数量必须为数值'
          },
          { validator: high, trigger: 'blur' }
        ],
        smallest: [
          {
            required: true,
            trigger: 'blur',
            message: '最少数量不能为空'
          },
          {
            type: 'number',
            min: 0,
            message: '最少数量必须为数值'
          },
          { validator: low, trigger: 'blur' }
        ],
        price: [
          {
            required: true,
            trigger: 'blur',
            message: '服务价格不能为空'
          },
          {
            type: 'number',
            min: 0,
            message: '价格必须为数值'
          }
        ],
        desciption: [
          {
            required: true,
            trigger: 'blur',
            message: '服务描述不能为空'
          }
        ],
        state: [
          {
            required: true,
            trigger: 'blur',
            message: '服务状态不能为空'
          }
        ]
      }
    }
  },
  watch: {},
  created() {
    // this.shoppingForm = this.addshopping
    // console.log(this.addshopping, 'd')
  },
  methods: {
    // 添加服务
    async addShopping() {
      await this.$refs.shoppingForm.validate(async valid => {
        if (valid) {
          await addshopping(this.shoppingForm)
          this.closeDialog()
        } else {
          return false
        }
      })
    },
    // 取消
    closeDialog() {
      this.$refs.shoppingForm.resetFields()
      this.$emit('closedialog', false)
    }
  }
}
</script>

<style scoped>
.shortInput {
  display: inline-block;
  width: 80px;
}
</style>
